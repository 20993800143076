import $ from 'jquery';

// Extend jQuery
$.extend({
	isBoolean: function(o) {
		return typeof o === 'boolean';
	},
	isNull: function(o) {
		return o === null;
	},
	isNumber: function(o) {
		return typeof o === 'number' && isFinite(o);
	},
	isObject: function(o) {
		return (o && (typeof o === 'object' || this.isFunction(o))) || false;
	},
	isString: function(o) {
		return typeof o === 'string';
	},
	isUndefined: function(o) {
		return typeof o === 'undefined';
	},
	isValue: function(o) {
		return (this.isObject(o) || this.isString(o) || this.isNumber(o) || this.isBoolean(o));
	},
	isDate: function(o) {
		return this.type(o) === 'date' && o.toString() !== 'Invalid Date' && !isNaN(o);
	},
	later: function(when, o, fn, data, periodic) {
		when = when || 0;
		o = o || {};
		var m = fn, d = data, f, r;
		if (this.isString(fn))
			m = o[fn];
		if (!m)
			return;
		if (!this.isUndefined(data) && !this.isArray(d))
			d = [data];
		f = function() {
			m.apply(o, d || []);
		};
		r = (periodic) ? setInterval(f, when) : setTimeout(f, when);
		return {
			interval: periodic,
			cancel: function() {
				if (this.interval) {
					clearInterval(r);
				} else {
					clearTimeout(r);
				}
			}
		};
	}
});
import $ from 'jquery';

/**
 * Class Forms
 */
export default class Forms {
    _forms = {};

    /**
     * Get form by element or id
     * @param {HTMLElement|string} el Element or id
     */
    get(el) {
        let id = ($.isString(el)) ? el : ew.getForm(el).id;
        return this._forms[id];
    }

    /**
     * Add form
     * @param {Form} f Form
     */
    add(f) {
        this._forms[f.id] = f;
    }

    /**
     * Get all ids
     * @returns {string[]}
     */
    ids() {
        return Object.keys(this._forms);
    }

}
import $ from 'jquery';

// Form class
export default function FormBase(id, pageId) {
    var self = this;

    this._initiated = false;
    this.id = id; // Same ID as the form
    this.pageId = pageId;
    this.$element = null; // jQuery object of the form or div
    this.htmlForm = null; // HTML form element
    this.initSearchPanel = false; // Expanded by default
    this.modified = false;
    this.validateRequired = true;
    this.validate = null;
    this.emptyRow = null; // Check empty row
    this.multiPage = null; // Multi-page
    this.autoSuggests = {}; // AutoSuggests
    this.lists = {}; // Dynamic selection lists

    // Disable form
    this.disableForm = function() {
        var form = this.getForm();
        $(form).find(":submit:not(.dropdown-toggle)").prop("disabled", true).addClass("disabled");
    }

    // Enable form
    this.enableForm = function() {
        var form = this.getForm(),
            $form = $(form);
        $form.find(".ew-disabled-element").removeClass("ew-disabled-element").prop("disabled", false);
        $form.find(".ew-enabled-element").removeClass("ew-enabled-element").prop("disabled", true);
        $form.find(":submit:not(.dropdown-toggle)").prop("disabled", false).removeClass("disabled");
    }

    // Append hidden element with form name
    this.appendHidden = function(el) {
        var form = this.getForm(), $form = $(form), $dp = $(el).closest(".ew-form"), name = $dp.attr("id") + "$" + el.name;
        if ($form.find("input:hidden[name='" + name + "']")[0]) // Already appended
            return;
        var ar = $dp.find('[name="' + el.name + '"]').serializeArray();
        if (ar.length) {
            ar.forEach(function(o, i) {
                $('<input type="hidden" name="' + name + '">').val(o.value).appendTo($form);
            });
        } else {
            $('<input type="hidden" name="' + name + '">').val("").appendTo($form);
        }
    }

    // Can submit
    this.canSubmit = function() {
        var form = this.getForm(),
            $form = $(form);
        this.disableForm();
        this.updateTextArea();
        if (!this.validate || this.validate() && !$form.find(".is-invalid")[0]) {
            $form.find("input[name^=sv_], input[name^=p_], .ew-template input") // Do not submit these values
                .prop("disabled", true)
                .addClass("ew-disabled-element");
            $form.find("[data-readonly=1][disabled]")
                .prop("disabled", false)
                .addClass("ew-enabled-element"); // Submit readonly values
            var $dps = $($form.find("input[name='detailpage']").map(function(i, el) {
                return $form.find("#" + el.value).get();
            }));
            if ($dps.length > 1) { // Multiple Master/Detail, check element names
                $dps.each(function(i, dp) {
                    $(dp).find(":input").each(function(j, el) {
                        if (/^(fn_)?(x|o)\d*_/.test(el.name)) {
                            var $els = $dps.not(dp).find(":input[name='" + el.name + "']");
                            if ($els.length) { // Elements with same name found
                                self.appendHidden(el); // Append element with form name
                                $els.each(function() {
                                    self.appendHidden(this); // Append elements with same name and form name
                                });
                            }
                        }
                    });
                });
            }
            return true;
        } else {
            this.enableForm();
        }
        return false;
    }

    // Submit
    this.submit = function(action) {
        var form = this.getForm();
        if (this.canSubmit()) {
            if (action)
                form.action = action;
            form.submit();
        }
        return false;
    }

    // Get dynamic selection list by element name or id
    this.getList = function(name) {
        name = name.replace(/^(sv_)?[xy](\d*|\$rowindex\$)_|\[\]$/g, ""); // Remove element name prefix/suffix
        return this.lists[name];
    }

    // Compile templates
    this.compileTemplates = function() {
        let lists = Object.values(this.lists);
        for (let list of lists) {
            if (list.template && $.isString(list.template))
                list.template = $.templates(list.template);
        }
    }

    // Get the HTML form element
    this.getForm = function() {
        if (!this.htmlForm) {
            this.$element = $("#" + this.id);
            if (this.$element.is("form")) { // HTML form
                this.htmlForm = this.$element[0];
            } else if (this.$element.is("div")) { // HTML div => Grid page
                this.htmlForm = this.$element.closest("form")[0];
            }
        }
        return this.htmlForm;
    }

    // Get form element as single element
    this.getElement = function(name) {
        if (!this.$element)
            this.$element = $("#" + this.id);
        return (name) ? ew.getElement(name, this.$element) : this.$element[0];
    }

    // Get form element(s) as single element or array of radio/checkbox
    this.getElements = function(name) {
        if (!this.$element)
            this.$element = $("#" + this.id);
        var selector = "[name='" + name + "']";
        selector = "input" + selector + ",select" + selector + ",textarea" + selector + ",button" + selector;
        var $els = this.$element.find(selector);
        return ($els.length == 0) ? null : ($els.length == 1 && $els.is(":not([type=checkbox]):not([type=radio])")) ? $els[0] : $els.get();
    }

    // Update selection lists
    // @param {(null|undefined|number)*} rowindex Row index
    this.updateLists = function(rowindex) {
        if (rowindex === null) // rowindex == $rowindex$ == null
            return;
        if (!$.isNumber(rowindex) && this.pageId == "grid")
            return;
        var form = this.getForm(), // Set up $element and htmlForm
            confirm = form.querySelector("input#confirm");
        if (confirm && confirm.value == "confirm") { // Confirm page
            ew.removeSpinner();
            return;
        }
        var fixId = (id, multiple) => {
            var t = "",
                i = rowindex,
                ar = id.split(" ");
            if (ar.length > 1) {
                t = ar[0];
                i = "";
                id = ar[1];
            }
            let prefix = $.isNumber(i) ? "x" + i + "_" : "x_"; // Add row index
            if (id.startsWith("x_")) // Field element name
                id = id.replace(/^x_/, prefix);
            else // Field var
                id = prefix + id;
            if (multiple && !id.endsWith("[]")) // Add [] if select-multiple
                id += "[]";
            return t ? t + " " + id : id;
        };
        var selector = Object.entries(this.lists).map(([id, list]) => {
            return "[name='" + fixId(id, list.multiple) + "']";
        }).join();
        if (!selector || !form.querySelector(selector)) { // Lists not found
            ew.removeSpinner();
            return;
        }
        var actions = [],
            promises = [];
        this.compileTemplates(); // For grid where updateList() called before init()
        for (let [id, list] of Object.entries(this.lists)) {
            let parents = list.parentFields.slice().map(parent => fixId(parent)), // Clone and fix index
                ajax = list.ajax;
            id = fixId(id, list.multiple);
            if ($.isBoolean(ajax)) { // Ajax
                let pvalues = parents.map(parent => ew.getOptionValues(parent, form)); // Save the initial values of the parent lists
                actions.push([id, pvalues, ajax, false]);
            } else { // Non-Ajax
                ew.updateOptions.call(this, id, parents, null, false);
            }
        }
        // Update the Ajax lists
        for (var i = 0; i < actions.length; i++) {
            promises.push(new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(ew.updateOptions.apply(self, actions.shift()));
                }, ew.AJAX_DELAY * i); // Delay a little in case of large number of lists
            }));
        }
        Promise.all(promises).then(function() {
            $(document).trigger("updatedone", [{source: self, target: form}]);
        }).catch(function(error) {
            console.log(error);
        });
    }

    // Create AutoSuggest
    this.createAutoSuggest = function(settings) {
        var options = Object.assign({
            limit: ew.AUTO_SUGGEST_MAX_ENTRIES,
            form: this
        }, ew.autoSuggestSettings, settings); // Global settings + field specific settings
        self.autoSuggests[settings.id] = new ew.AutoSuggest(options);
    }

    // Init editors
    this.initEditors = function() {
        var form = this.getForm();
        $(form.elements).filter("textarea.editor").each(function(i, el) {
            var ed = $(el).data("editor");
            if (ed && !ed.active && !ed.name.includes("$rowindex$"))
                ed.create();
        });
    }

    // Update textareas
    this.updateTextArea = function(name) {
        var form = this.getForm();
        $(form.elements).filter("textarea.editor").each(function(i, el) {
            var ed = $(el).data("editor");
            if (!ed || name && ed.name != name)
                return true; // Continue
            ed.save();
            if (name)
                return false; // Break
        });
    }

    // Destroy editor(s)
    this.destroyEditor = function(name) {
        var form = this.getForm();
        $(form.elements).filter("textarea.editor").each(function(i, el) {
            var ed = $(el).data("editor");
            if (!ed || name && ed.name != name)
                return true; // Continue
            ed.destroy();
            if (name)
                return false; // Break
        });
    }

    // Show error message
    this.onError = function(el, msg) {
        return ew.onError(this, el, msg);
    }

    // Init file upload
    this.initUpload = function() {
        var form = this.getForm();
        $(form.elements).filter("input:file:not([name*='$rowindex$'],[id='importfiles'])").each(function(index) {
            $.later(ew.AJAX_DELAY * index, null, ew.upload, this); // Delay a little in case of large number of upload fields
        });
    }

    // Set up filters
    this.setupFilters = function(e, filters) {
        var id = this.id, data = this.filterList ? this.filterList.data : null, $sf = $(".ew-save-filter[data-form=" + id + "]").toggleClass("disabled", !data),
            $df = $(".ew-delete-filter[data-form=" + id + "]").toggleClass("disabled", !filters.length).toggleClass("dropdown-toggle", !!filters.length),
            $delete = $df.parent("li").toggleClass("dropdown-submenu dropdown-hover", !!filters.length).toggleClass("disabled", !filters.length),
            $save = $sf.parent("li").toggleClass("disabled", !data),
            $btn = $(e.target);
        var saveFilters = function(id, filters) {
            if (ew.SEARCH_FILTER_OPTION == "Client") {
                window.localStorage.setItem(id + "_filters", JSON.stringify(filters));
            } else if (ew.SEARCH_FILTER_OPTION == "Server") {
                var $body = $("body");
                $body.css("cursor", "wait");
                $.ajax(ew.currentPage(), {
                    type: "POST",
                    dataType: "json",
                    data: { "ajax": "savefilters", "filters": JSON.stringify(filters) }
                }).done(function(result) {
                    if (result[0] && result[0].success)
                        self.filterList.filters = filters; // Save filters
                }).always(function() {
                    $body.css("cursor", "default");
                });
            }
        }
        $save.off("click.ew").on("click.ew", function(e) { // Save filter
            if ($save.hasClass("disabled"))
                return false;
            ew.prompt(ew.language.phrase("EnterFilterName"), (name) => {
                if (name) {
                    filters.push([name, data]);
                    saveFilters(id, filters);
                }
            }, true);
        }).prevAll().remove();
        $df.next("ul.dropdown-menu").remove();
        if (filters.length) {
            var $submenu = $("<ul class='dropdown-menu'></ul>");
            for (var i in filters) {
                if (!Array.isArray(filters[i]))
                    continue;
                $('<li><a class="dropdown-item" data-index="' + i + '" href="#" onclick="return false;">' + filters[i][0] + '</a></li>').on("click", function(e) { // Delete
                    var i = $(this).find("a[data-index]").data("index");
                    ew.prompt(ew.language.phrase("DeleteFilterConfirm").replace("%s", filters[i][0]), (result) => {
                        if (result) {
                            filters.splice(i, 1);
                            saveFilters(id, filters);
                        }
                    });
                }).appendTo($submenu);
                $('<li><a class="dropdown-item ew-filter-list" data-index="' + i + '" href="#" onclick="return false;">' + filters[i][0] + '</a></li>').insertBefore($save).on("click", function(e) {
                    var i = $(this).find("a[data-index]").data("index");
                    $("<form>").attr({method: "post", action: ew.currentPage()})
                        .append($("<input type='hidden'>").attr({name: "cmd", value: "resetfilter"}),
                            $("<input type='hidden'>").attr({name: ew.TOKEN_NAME_KEY, value: ew.TOKEN_NAME}), // PHP
                            $("<input type='hidden'>").attr({name: ew.ANTIFORGERY_TOKEN_KEY, value: ew.ANTIFORGERY_TOKEN}), // PHP
                            $("<input type='hidden'>").attr({name: "filter", value: JSON.stringify(filters[i][1])}))
                        .appendTo("body").submit();
                });
            }
            $("<li class='dropdown-divider'></li>").insertBefore($save);
            $delete.append($submenu);
        }
    }

    // Init form
    this.init = function() {
        if (this._initiated)
            return;

        // Filters button
        if (ew.SEARCH_FILTER_OPTION == "Client" && window.localStorage || ew.SEARCH_FILTER_OPTION == "Server" && ew.IS_LOGGEDIN && !ew.IS_SYS_ADMIN && ew.CURRENT_USER_NAME != "") {
            $(".ew-filter-option." + this.id + " .ew-btn-dropdown").on("show.bs.dropdown", function(e) {
                var filters = [];
                if (ew.SEARCH_FILTER_OPTION == "Client") {
                    var item = window.localStorage.getItem(self.id + "_filters");
                    if (item)
                        filters = ew.parseJson(item) || [];
                } else if (ew.SEARCH_FILTER_OPTION == "Server")
                    filters = self.filterList && self.filterList.filters ? self.filterList.filters : [];
                var ar = $.grep(filters, function(val) {
                    if (Array.isArray(val) && val.length == 2)
                        return val;
                });
                self.setupFilters(e, ar);
            });
            $(".ew-filter-option").show();
        } else {
            $(".ew-filter-option").hide();
        }

        // Check form
        var form = this.getForm(),
            $form = $(form);
        if (!form)
            return;

        // Compile templates
        this.compileTemplates();

        // Check if Search panel
        var isSearch = /s(ea)?rch$/.test(this.id);

        // Search panel
        if (isSearch && this.initSearchPanel && !ew.hasFormData(form))
            $("#" + this.id + "-search-panel").removeClass("show");

        // Search panel toggle
        $(".ew-search-toggle[data-form=" + this.id + "]").on("click.bs.button", function() {
            $("#" + $(this).data("form") + "-search-panel").collapse("toggle");
        });

        // Hide search operator column
        if (!$(".ew-table .ew-search-operator").text().trim())
            $(".ew-table .ew-search-operator").parent("td").hide();

        // Highlight button
        if (isSearch) {
            $(".ew-highlight[data-form=" + this.id + "]").on("click.bs.button", function() {
                $("span." + $(this).data("name")).toggleClass("ew-highlight-search");
            });
        }

        // Search operators
        if (isSearch) { // Search form
            $form.find("select[id^=z_]").each(function() {
                var $this = $(this).trigger("change");
                if ($this.val() != "BETWEEN")
                    $form.find("#w_" + this.id.substr(2)).trigger("change");
            });
        }

        // Multi-page
        if (this.multiPage)
            this.multiPage.render();

        // HTML editors
        loadjs.ready(["editor"], this.initEditors.bind(this));

        // Dynamic selection lists
        this.updateLists();

        // Init file upload
        this.initUpload();

        // Submit/Cancel
        if (this.$element.is("form")) { // Not Grid page
            // Detail pages
            this.$element.find(".ew-detail-pages .ew-nav-tabs a[data-toggle=tab]").on("shown.bs.tab", function(e) {
                var $tab = $(e.target.getAttribute("href")),
                    $panel = $tab.find(".table-responsive.ew-grid-middle-panel"),
                    $container = $tab.closest(".container-fluid");
                if ($panel.width() >= $container.width())
                    $panel.width($container.width() + "px");
                else
                    $panel.width("auto");
            });
            $form.submit(function(e) { // Bind submit event
                return self.submit();
            });
            $form.find("[data-field], .ew-priv").on("change", function() {
                if (ew.CONFIRM_CANCEL)
                    self.modified = true;
            });
            $form.find("#btn-cancel[data-href]").on("click", function() { // Cancel
                self.updateTextArea();
                var href = $(this).data("href");
                if (self.modified && ew.hasFormData(form)) {
                    ew.prompt(ew.language.phrase("ConfirmCancel"), (result) => {
                        if (result) {
                            $form.find("#btn-action").prop("disabled", true); // Disable the save button
                            window.location = href;
                        }
                    });
                } else {
                    $form.find("#btn-action").prop("disabled", true); // Disable the save button
                    window.location = href;
                }
            });
        }

        this._initiated = true;

        // Store form object as data
        this.$element.data("form", this);
    }

    // Add to the global forms object
    ew.forms.add(this);
}
import $ from "jquery";

export default function MultiPage(formid) {
	var self = this;
	this.$form = null;
	this.formID = formid;
	this.pageIndex = 1;
	this.maxPageIndex = 0;
	this.minPageIndex = 0;
	this.pageIndexes = [];
	this.$pages = null;
	this.$collapses = null;
	this.isTab = false; // Is tabs
	this.isCollapse = false; // Is collapses (accordion)
	this.lastPageSubmit = false; // Enable submit button for the last page only
	this.hideDisabledButton = false; // Hide disabled submit button
	this.hideInactivePages = false; // Hide inactive pages
	this.lockTabs = false; // Set inactive tabs as disabled
	this.hideTabs = false; // Hide all tabs
	this.showPagerTop = false; // Show pager at top
	this.showPagerBottom = false; // Show pager at bottom
	this.pagerTemplate = '<nav><ul class="pagination"><li class="page-item previous ew-prev"><a href="#" class="page-link"><span class="icon-prev"></span> {Prev}</a></li><li class="page-item next ew-next"><a href="#" class="page-link">{Next} <span class="icon-next"></span></a></li></ul></nav>'; // Pager template

	// "show" handler (for disabled tabs)
	var _show = function(e) {
		e.preventDefault();
	};

	// Set properties
	var _properties = ["lastPageSubmit", "hideDisabledButton", "hideInactivePages", "lockTabs",
		"hideTabs", "showPagerTop", "showPagerBottom", "pagerTemplate"];

	this.set = function() {
		if (arguments.length == 1 && $.isObject(arguments[0])) {
			var obj = arguments[0];
			for (var i in obj) {
				var p = i[0].toLowerCase() + i.substr(1); // Camel case
				if (_properties.includes(p))
					this[p] = obj[i];
			}
		}
	}

	// DOM loaded
	this.init = function() {
		var tpl = this.pagerTemplate.replace(/\{prev\}/i, ew.language.phrase("Prev")).replace(/\{next\}/i, ew.language.phrase("Next"));
		if (this.isTab) {
			if (this.showPagerTop)
				this.$pages.closest(".tabbable, .ew-nav-tabs").before(tpl);
			if (this.showPagerBottom)
				this.$pages.closest(".tabbable, .ew-nav-tabs").after(tpl);
			this.$form.find(".ew-prev").click(function(e) {
				self.$pages.off("show.bs.tab", _show).filter(".active").parent()
					.prev(":has([data-toggle=tab]:not(.ew-hidden):not(.ew-disabled))")
					.find("[data-toggle=tab]").toggleClass("disabled d-none", false).click();
				return false;
			});
			this.$form.find(".ew-next").click(function(e) {
				self.$pages.off("show.bs.tab", _show).filter(".active").parent()
					.next(":has([data-toggle=tab]:not(.ew-hidden):not(.ew-disabled))")
					.find("[data-toggle=tab]").toggleClass("disabled d-none", false).click();
				return false;
			});
			if (this.hideTabs)
				this.$form.find(".ew-multi-page > .tabbable > .nav-tabs, .ew-multi-page > .ew-nav-tabs > .nav-tabs").hide();
		} else if (this.isCollapse) {
			if (this.showPagerTop)
				this.$collapses.closest(".ew-accordion").before(tpl);
			if (this.showPagerBottom)
				this.$collapses.closest(".ew-accordion").after(tpl);
			this.$form.find(".ew-prev").click(function(e) {
				self.$pages.closest(".card").filter(":has(.collapse.show)")
					.prev(":has([data-toggle=collapse]:not(.ew-hidden):not(.ew-disabled))")
					.toggleClass("disabled d-none", false)
					.find("[data-toggle=collapse]").click();
				return false;
			});
			this.$form.find(".ew-next").click(function(e) {
				self.$pages.closest(".card").filter(":has(.collapse.show)")
					.next(":has([data-toggle=collapse]:not(.ew-hidden):not(.ew-disabled))")
					.toggleClass("disabled d-none", false)
					.find("[data-toggle=collapse]").click();
				return false;
			});
		}
		this.pageShow();
	}

	// Page show
	this.pageShow = function() {
		if (this.isTab) {
			if (this.lockTabs)
				this.$pages.on("show.bs.tab", _show);
			this.$pages.each(function() {
				var $this = $(this);
				if (self.hideInactivePages)
					$this.toggleClass("d-none", !$this.hasClass("active"));
				if (self.lockTabs)
					$this.toggleClass("disabled", !$this.hasClass("active"));
			});
		} else if (this.isCollapse) {
			this.$pages.closest(".card").each(function() {
				var $this = $(this);
				if (self.hideInactivePages)
					$this.toggleClass("d-none", !$this.find(".collapse.show")[0]);
			});
		}
		var disabled = this.lastPageSubmit && this.pageIndex != this.maxPageIndex;
		var $btn = this.$form.closest(".content, .modal-content").find("#btn-action, button.ew-submit").prop("disabled", disabled).toggle(!this.hideDisabledButton || !disabled);
		$(".ew-captcha").toggle($btn.is(":visible:not(:disabled)")); // Re-captcha uses class "disabled", not "disabled" property.
		disabled = this.pageIndex <= this.minPageIndex;
		this.$form.find(".ew-prev").toggleClass("disabled", disabled);
		disabled = this.pageIndex >= this.maxPageIndex;
		this.$form.find(".ew-next").toggleClass("disabled", disabled);
	}

	// Go to page by index
	this.gotoPage = function(i) {
		if (i <= 0 || i < this.minPageIndex || i > this.maxPageIndex)
			return;
		if (this.pageIndex != i) {
			var $page = this.$pages.eq(i - 1);
			if (this.isTab) {
				if ($page.is(":not(.d-none):not(.disabled)"))
					$page.click();
				else
					$page.parent().next(":has([data-toggle=tab]):not(.d-none):not(.disabled)")
						.find("[data-toggle=tab]").toggleClass("disabled", false).click();
			} else if (this.isCollapse) {
				var $p = $page.closest(".card");
				if ($p.is(":not(.d-none)"))
					$page.click();
				else
					$p.next(":has([data-toggle=collapse]):not(.d-none)").find("[data-toggle=collapse]").click();
			}
			this.pageIndex = i;
		}
	}

	this.gotoPageByIndex = this.gotoPage;

	// Go to page by element
	this.gotoPageByElement = function(el) {
		this.gotoPage(parseInt($(el).data("page"), 10) || -1);
	}

	// Go to page by element's id or name or data-field attribute
	this.gotoPageByElementId = function(id) {
		var $el = this.$form.find("[data-page]").filter("[id='" + id + "'],[name='" + id + "'],[data-field='" + id + "']");
		this.gotoPageByElement($el);
	}

	// Toggle page
	this.togglePage = function(i, show) {
		if (this.isTab) {
			this.$pages.eq(i - 1).toggleClass("d-none", !show);
		} else if (this.isCollapse) {
			this.$pages.eq(i - 1).closest(".card").toggle("d-none", !show);
		}
	}

	// Render
	this.render = function() {
		this.$form = $("#" + formid);
		this.pageIndexes = this.$form.find("[data-page]").map(function() {
			var index = parseInt($(this).data("page"), 10);
			return (index > 0) ? index : null;
		}).get();
		this.pageIndexes.sort(function(a, b) {
			return a - b;
		});
		this.minPageIndex = this.pageIndexes[0];
		this.maxPageIndex = this.pageIndexes[this.pageIndexes.length - 1];
		var $tabs = this.$form.find("[data-toggle=tab]");
		if ($tabs[0]) {
			this.$pages = $tabs;
			this.isTab = true;
			$tabs.on("shown.bs.tab", function(e) {
				self.pageIndex = $tabs.index(e.target) + 1;
				self.pageShow();
				$($(this).attr("href")).find(".ew-google-map").each(function() {
					var m = ew.googleMaps[this.id];
					if (m && m["map"]) {
						google.maps.event.trigger(m["map"], "resize");
						m["map"].setCenter(m["latlng"]);
					}
				});
			});
			this.pageIndex = $tabs.index($tabs.parent(".active")) + 1;
		} else {
			this.$collapses = this.$form.find("[data-toggle=collapse]");
			if (this.$collapses[0]) {
				this.$pages = this.$collapses;
				this.isCollapse = true;
				var $bodies = this.$collapses.closest(".card-header").next();
				$bodies.on("shown.bs.collapse", function(e) {
					self.pageIndex = $bodies.index(e.target) + 1;
					self.pageShow();
					$(this).find(".ew-google-map").each(function() {
						var m = ew.googleMaps[this.id];
						if (m && m["map"]) {
							google.maps.event.trigger(m["map"], "resize");
							m["map"].setCenter(m["latlng"]);
						}
					});
				});
				this.pageIndex = $bodies.index($bodies.hasClass("show")) + 1;
			}
		}
		$(function() {
			self.init();
		});
	}
}
import $ from 'jquery';

// Init Treeview later
$(window).off("load.lte.treeview");

// Treeview
var Treeview = adminlte.Treeview;
Treeview.prototype._toggle = Treeview.prototype.toggle;
Treeview.prototype.toggle = function toggle(event) {
	var $relativeTarget = $(event.currentTarget), treeviewMenu = $relativeTarget.next(),
		href = $relativeTarget.attr("href"), $text = $(event.target).closest(".menu-item-text");
	if (!treeviewMenu.is(".nav-treeview") || $text[0] && href && href != "#" && href != "javascript:void(0);") // Menu text with href
		return;
	this._toggle(event);
};

// Dropdown menu parent item with href // Override AdminLTE
$("ul.dropdown-menu [data-toggle=dropdown]").on("click", function(e) {
	var href = $(this).attr("href");
	if (href && href != "#" && e.target.nodeName == "SPAN")
		window.location = href;
});